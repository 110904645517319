import { z } from 'zod'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRequired = (schema: z.ZodObject<any>, key: string) => {
  // if key is nested (dot notation) access nested
  // zodschema with .shape for each level:
  const keyParts = key.split('.')
  let schemaPart = schema
  for (const keyPart of keyParts) {
    schemaPart = schemaPart?.shape?.[keyPart]
  }
  if (!schemaPart) return false
  return !schemaPart.isOptional()
}

export { z }

export const fiscalDataSchema = z.object({
  satProductServiceKey: z.string().nullable().optional(),
  satUnitKey: z.string().nullable().optional(),
  satTaxObject: z.string().nullable().optional(),
})

export const paymentSchema = z.object({
  satCfdiUse: z.string(),
  satPaymentMethod: z.string(),
  satPaymentForm: z.string(),
  conditions: z.string(),
  currency: z.string(),
  exchangeRate: z.object({
    value: z.number(),
    date: z.string().datetime(),
  }),
})
